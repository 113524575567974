<template>
  <b-overlay :show="commentDetailBusy">
    <!--Begin:: App Content-->
    <div class="kt-grid__item kt-grid__item--fluid kt-app__content" id="kt_chat_content">
      <div class="kt-chat">
        <div class="kt-portlet kt-portlet--head-lg kt-portlet--last">
          <div class="kt-portlet__head">
            <div class="kt-chat__head ">
              <div class="kt-chat__left"></div>
              <div class="kt-chat__center">
                <div class="kt-chat__pic kt--hidden mb-1">
                  <b-avatar class="kt-media kt-media--sm" rounded="" :src="comment_detail.commenter ? comment_detail.commenter.avatar : ''"></b-avatar>
                </div>
                <div class="kt-chat__label">
                  <span class="kt-chat__status">
                    {{ comment_detail.commenter ? comment_detail.commenter.nickname : '' }}
                  </span>
                </div>
              </div>
              <div class="kt-chat__right">
                <button @click="$router.back()" type="button" class="btn btn-clean btn-sm btn-icon btn-icon-md ">
                  <i class="fa fa-angle-left"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="kt-portlet__body">
            <div class="kt-todo">
              <!--Begin:: Inbox View-->
              <div class="kt-todo__view">
                <!--Begin:: Wrapper-->
                <div class="kt-todo__wrapper">
                  <!--Begin:: Body-->
                  <div class="kt-todo__body">
                    <div class="kt-todo__desc">
                      {{ comment_detail.comment }}
                    </div>
                    <div class="kt-todo__title">
                      <div class="kt-todo__labels">
                        <span class="kt-todo__label">
                          <span @click="onChangeSwitch( comment_detail.id, 'approved', $event )" v-if="!comment_detail.approved" class="kt-todo__text text-warning">{{ $t('PAGES.COMMENTS.DETAIL.BUTTONS.AUDIT') }}</span>
                          <span @click="onChangeSwitch( comment_detail.id, 'approved', $event )" v-else class="kt-todo__text text-success">{{ $t('PAGES.COMMENTS.DETAIL.BUTTONS.AUDITED') }}</span>
                        </span>
                        <span class="kt-todo__label">
                          <span @click="onClickSetTop(comment_detail.id)" v-if="!comment_detail.is_top" class="kt-todo__text text-warning">{{$t('PAGES.COMMENTS.DETAIL.BUTTONS.SET_TOP')}}</span>
                          <span @click="onClickSetTop(comment_detail.id)" v-else class="kt-todo__text text-success">{{$t('PAGES.COMMENTS.DETAIL.BUTTONS.DE_TOP')}}</span>
                        </span>
                        <span class="kt-todo__label">
                          <span @click="onClickDelete(comment_detail.id)" class="kt-todo__text text-danger">{{ $t('PAGES.COMMENTS.DETAIL.BUTTONS.DELETE') }}</span>
                        </span>
                      </div>
                    </div>
                    <div class="border-bottom border-light mt-3" v-if="comment_detail.children && comment_detail.children.length"></div>
                    <div class="kt-todo__comments" v-if="comment_detail.children && comment_detail.children.length">
                      <div v-for="comment in comment_detail.children" :key="comment.id" class="kt-todo__comment">
                        <div class="kt-todo__box">
                          <!--<span class="kt-media kt-media&#45;&#45;sm" data-toggle="expand" style="background-image: url('assets/media/users/100_1.jpg')">
                            <span></span>
                          </span>-->
                          <b-avatar class="kt-media kt-media--sm" rounded="" :src="comment.commenter.avatar"></b-avatar>
                          <a class="kt-todo__username cursor-default">
                            {{ comment.commenter.nickname }}
                          </a>
                          <span class="kt-todo__date">{{ comment.created_at }}</span>
                        </div>
                        <span class="kt-todo__text">{{ comment.comment }}</span>
                        <div class="kt-todo__title">
                          <div class="kt-todo__labels">
                            <span class="kt-todo__label">
                              <span @click="onChangeSwitch( comment.id, 'approved', $event )" v-if="!comment.approved" class="kt-todo__text text-warning">{{ $t('PAGES.COMMENTS.DETAIL.BUTTONS.AUDIT') }}</span>
                              <span @click="onChangeSwitch( comment.id, 'approved', $event )" v-else class="kt-todo__text text-success">{{ $t('PAGES.COMMENTS.DETAIL.BUTTONS.AUDITED') }}</span>
                            </span>
                            <span class="kt-todo__label">
                              <span @click="onClickDelete(comment.id, 2)" class="kt-todo__text text-danger">{{$t('PAGES.COMMENTS.DETAIL.BUTTONS.DELETE')}}</span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--End:: Body-->
                </div>
                <!--End:: Wrapper-->
              </div>
              <!--End:: Inbox View-->
            </div>
          </div>
          <div class="kt-portlet__foot">
            <div class="kt-chat__input" v-if="false">
              <div class="kt-chat__editor">
                <textarea v-model="formData.comment" style="height: 50px" :placeholder="$t('PAGES.COMMENTS.DETAIL.PLACEHOLDER.typing')"></textarea>
              </div>
              <div class="kt-chat__toolbar">
                <div class="kt_chat__tools">
  <!--                <a href="#"><i class="flaticon2-link"></i></a>-->
  <!--                <a href="#"><i class="flaticon2-photograph"></i></a>-->
  <!--                <a href="#"><i class="flaticon2-photo-camera"></i></a>-->
                </div>
                <div class="kt_chat__actions">
                  <button :disabled="!formData.comment" @click="onClickReply" type="button" class="btn btn-brand btn-md btn-upper btn-bold kt-chat__reply">{{$t('PAGES.COMMENTS.DETAIL.BUTTONS.REPLY')}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--End:: App Content-->
  </b-overlay>
</template>

<script>
  import commentMixin from "@m@/comment.mixin";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import { mapGetters } from "vuex";
  import commonMethodMixin from "@m@/common/common.method.mixin";
  import msgBoxConfirmMixin from "@m@/common/msgBoxConfirmMixin";
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";
  import {SET_COMMENT_DETAIL, SET_COMMENT_ERROR} from "@/store/modules/comment.module";
  export default {
    name: "index",
    props: {
      id: {
        type: [String, Number]
      }
    },
    mixins: [commentMixin, formBusyMixin, commonMethodMixin, msgBoxConfirmMixin, sweetAlertMixin],
    computed: {
      ...mapGetters(['comment_detail', 'comments_error'])
    },
    created() {
      this.getCommentDetail(this.id)
    },
    data(){
      return {
        commentDetailBusy: false,
        formData: {
          comment: ''
        }
      }
    },
    methods: {
      onChangeSwitch(id, column, value){
        this.switchColumn({
          resource: 'comments',
          id, column, mutationType: SET_COMMENT_ERROR,
          begin: ()=>{ this.showBusy('CommentDetailBusy') },
          after: ()=>{ this.hideBusy('CommentDetailBusy') },
        }).then((res)=>{
          let { approved } = res.data;
          let x = this.comment_detail;
          if( x.id === id ){
            x.approved = approved;
          }
          if( x.children.length ){
            x.children = x.children.map(function (item) {
              if( item.id === id ){
                item.approved = approved;
              }
              return item;
            })
          }
          this.$store.commit(SET_COMMENT_DETAIL, x);
        })
      },
      onClickDelete(id, action = 1){
        let message = this.$t("PAGES.COMMENTS.DETAIL.TIPS.CONFIRM");
        if( action === 1 && this.comment_detail && this.comment_detail.children.length ){
          let len = this.comment_detail.children.length;
          message = this.$t('PAGES.COMMENTS.DETAIL.TIPS.CONFIRM_WITH_LEN', {len});
        }
        this.msgBoxConfirm({
          message,
          confirmCallback: confirm=>{
            if ( confirm ){
              this.deleteComment(id,{
                begin: ()=>{ this.showBusy('commentDetailBusy')},
                after: ()=>{
                  this.getCommentDetail(this.id)
                    .catch(()=>{
                      this.$router.replace({name: 'comment.home'});
                    })
                  this.hideBusy('commentDetailBusy');
                },
              })
            }
          }
        });
      },
      onClickReply(){
        if( this.formData.comment ){
          this.replyComment(this.id, this.formData)
            .then((res)=>{
              this.formData.comment = '';
              let { data } = res;
              this.comment_detail.children.push( data );
            }).catch((res)=>{
              this.warning({ message: this.$t("PAGES.COMMENTS.DETAIL.TIPS.ERROR") });
          })
        }
      },
      onClickSetTop(id){
        this.setTop(this.id)
          .then(res=> {
            const { is_top } = res.data;
            const comment = JSON.parse(JSON.stringify(this.comment_detail));
            comment.is_top = is_top
            this.$store.commit(SET_COMMENT_DETAIL, comment);
          })
          .catch((res)=>{
            this.warning({ message: this.$t("PAGES.COMMENTS.DETAIL.TIPS.ERROR") });
          })
      }
    }
  }
</script>

<style scoped>
.b-avatar-group {
  display: flex;
  justify-content: center;
}

.kt-todo .kt-todo__view {
  padding: 0;
  /*display: flex !important;*/
}

.kt-todo .kt-todo__view .kt-todo__wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  /*padding-bottom: 25px;*/
}

.kt-todo .kt-todo__view .kt-todo__wrapper .kt-todo__body {
  padding-bottom: 25px;
  flex-grow: 1;
}

.kt-todo .kt-todo__view .kt-todo__wrapper .kt-todo__body .kt-todo__title {
  /*padding-top: .5rem;*/
}

.kt-todo .kt-todo__view .kt-todo__wrapper .kt-todo__body .kt-todo__title .kt-todo__labels {
  display: flex;
  align-items: center;
  padding: 0.8rem 0 0.8rem 0;
}

.kt-todo .kt-todo__view .kt-todo__wrapper .kt-todo__body .kt-todo__title .kt-todo__labels .kt-todo__label {
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.kt-todo .kt-todo__view .kt-todo__wrapper .kt-todo__body .kt-todo__title .kt-todo__labels .kt-todo__label .kt-todo__text {
  color: #a2a5b9;
  font-weight: 500;
  font-size: 1rem;
  margin: 0;
  cursor: pointer;
}

.kt-todo .kt-todo__view .kt-todo__wrapper .kt-todo__body .kt-todo__desc {
  padding-bottom: 10px;
  display: block;
  color: #a2a5b9;
  font-weight: 500;
}

.kt-todo .kt-todo__view .kt-todo__wrapper .kt-todo__body .kt-todo__comments {
  /*padding-bottom: 20px;*/
}

.kt-todo .kt-todo__view .kt-todo__wrapper .kt-todo__body .kt-todo__comments .kt-todo__comment {
  padding-top: 3rem;
}

.kt-todo .kt-todo__view .kt-todo__wrapper .kt-todo__body .kt-todo__comments .kt-todo__comment .kt-todo__box {
  display: flex;
  align-items: center;
}

.kt-todo .kt-todo__view .kt-todo__wrapper .kt-todo__body .kt-todo__comments .kt-todo__comment .kt-todo__text {
  padding-right: 3.3rem;
  display: block;
  color: #a2a5b9;
  font-weight: 500;
}

.kt-todo .kt-todo__view .kt-todo__wrapper .kt-todo__body .kt-todo__comments .kt-todo__comment .kt-todo__box .kt-media {
  margin-left: 1rem;
}

.kt-media {
  display: inline-flex;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 4px;
}

.kt-todo .kt-todo__view .kt-todo__wrapper .kt-todo__body .kt-todo__comments .kt-todo__comment .kt-todo__box .kt-todo__username {
  flex-grow: 1;
  color: #595d6e;
  font-weight: 500;
}

.kt-todo .kt-todo__view .kt-todo__wrapper .kt-todo__body .kt-todo__comments .kt-todo__comment .kt-todo__box .kt-todo__date {
  color: #a2a5b9;
  font-weight: 500;
}
</style>
